import {
  ConventionTypeValues,
  ExportAuthorization,
  Organization,
  OrganizationDetails,
  OrganizationType,
  PackageType,
  PaymentDetails,
} from "../../../api/organization";
import { LogoType } from "../../../api/organization/service";
import { buildOrganizationApiUrl } from "../../../api/configuration";
import { OrganizationDetailsValidation } from "./validation";

export interface OrganizationDetailsFormState {
  friendlyId: string;
  details: OrganizationDetails;
  type: OrganizationType;
  validity: OrganizationDetailsFormValidity;
  packageType?: PackageType;
  packagePerPallet?: number;
  logo?: LogoType;
  payment?: PaymentDetails;
  useDocusign?: boolean;
}

export interface OrganizationDetailsFormValidity {
  name?: string;
  siret?: string;
  street?: string;
  zipCode?: string;
  city?: string;
  type?: string;
  packageType?: string;
  packagePerPallet?: string;
  useDocusign?: boolean;
}

export const buildOrganizationDetailsForm = (
  organization: Organization
): OrganizationDetailsFormState => ({
  friendlyId: organization.friendlyId,
  details: organization.details,
  logo: {
    src: buildOrganizationApiUrl(`/organizations/${organization.id}/logo`),
  },
  validity: OrganizationDetailsValidation.validation(
    organization.details,
    organization.organizationType,
    organization.packageType,
    organization.packagePerPallet
  ),
  type: organization.organizationType,
  packageType: organization.packageType,
  packagePerPallet: organization.packagePerPallet,
  useDocusign: organization.conventionSelected?.value == ConventionTypeValues.DOCUSIGN.value
});
