import {
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import { Add, CloudUpload, VisibilityOutlined } from '@material-ui/icons'
import { Theme, themeContext } from '@smartb/archetypes-ui-components'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditDocument, Pdf } from '../../Icons'
import { Button } from '../Button'
import { PopUpConfirmation } from '../PopUp'
import ReactDropzone, { FileRejection } from 'react-dropzone'

const useStyles = (theme: Theme) =>
  makeStyles(() => ({
    error: {
      color: '#ed3e3e',
      width: '100%',
      textAlign: 'center'
    },
    image: {
      width: '100%',
      borderRadius: '2px'
    },
    icon: {
      width: '23px',
      height: '23px',
      display: 'inline',
      marginRight: '5px'
    },
    title: {
      color: '#353945',
      display: 'inline',
      fontWeight: 600
    },
    buttonIcon: {
      color: 'white',
      width: '26px',
      height: '26px',
      marginRight: '5px'
    },
    button: {
      width: '100px',
      marginBottom: '10px'
    },
    imageProgress: {
      color: theme.primaryColor
    }
  }))
interface ConventionViewerProps {
  title: string
  imageSrc: string
  onPrefill?: () => void
  onSign?: () => void
  onView?: () => void
  onReset?: () => void
  onUpload?: (file: File) => void
  signed?: boolean
  prefilled?: boolean
  conventionLoading?: boolean
  isDocusign: boolean
}

export const ConventionViewer = (props: ConventionViewerProps) => {
  const {
    imageSrc,
    title,
    onPrefill,
    onSign,
    onView,
    onReset,
    onUpload,
    signed = false,
    prefilled = false,
    conventionLoading,
    isDocusign
  } = props
  const theme = useContext(themeContext)
  const classes = useStyles(theme)()
  const { t } = useTranslation()
  const [error, setError] = useState(false)
  const [uploadErrorMessage, setUplaodErrorMessage] = useState<string|undefined>()
  const [loading, setLoading] = useState(true)

  const [openReset, setOpenReset] = useState(false)

  const handleOpenReset = useCallback(() => setOpenReset(true), [])
  const handleCloseReset = useCallback(() => setOpenReset(false), [])
  const handleReset = useCallback(async () => {
    onReset && (await onReset())
    handleCloseReset()
  }, [handleCloseReset, onReset])

  useEffect(() => {
    setError(false)
  }, [imageSrc])

  const onReject = (files: FileRejection[]) => {
    setUplaodErrorMessage('Insérez un seul fichier valide')
    files.forEach((file) => {
      if (file.errors[0].code === 'file-too-large')
      setUplaodErrorMessage('La taille de votre fichier ne doit pas dépasser 10Mo')
      if (file.errors[0].code === 'too-many-files')
      setUplaodErrorMessage("N'insérez qu'un seul fichier")
      if (file.errors[0].code === 'file-invalid-type')
      setUplaodErrorMessage('Seul les fichiers au format pdf sont acceptés')
    })
  }

  return (
    <Box
      display='flex'
      maxWidth='480px'
      minHeight='212px'
      flexWrap='wrap-reverse'
      justifyContent='space-between'
    >
      <Box
        display='flex'
        position='relative'
        width='150px'
        height={error || loading ? '212px' : ''}
        justifyContent='center'
        alignItems='center'
        style={{
          background: error || loading ? '#e3e3e3' : '',
          borderRadius: error || loading ? '2px' : ''
        }}
      >
        {!error && (
          <img
            src={imageSrc}
            alt={title}
            onError={() => {
              setError(true)
              setLoading(false)
            }}
            onLoad={() => setLoading(false)}
            className={classes.image}
            style={{ width: loading ? '0px' : '' }}
          />
        )}
        {error && (
          <Typography variant='body2' className={classes.error}>
            {t('app_organizations_documents_CA_Convention_error')}
          </Typography>
        )}
        {uploadErrorMessage && (
          <Typography variant='body2' className={classes.error}>
            {uploadErrorMessage}
          </Typography>
        )}
        {loading && (
          <CircularProgress size={30} className={classes.imageProgress} />
        )}
      </Box>
      <Box
        display='flex'
        width='300px'
        flexDirection='column'
        paddingTop='10px'
        paddingBottom='10px'
      >
        {!error && (
        <Box display='flex' alignItems='center' marginBottom='10px'>
          <Pdf color='#353945' className={classes.icon} />
          <Typography variant='body2' className={classes.title}>
            {title}
          </Typography>
        </Box>
        )}
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          height='100%'
        >
          {onUpload && (
          <ReactDropzone
            onDropAccepted={(files) => {
              setUplaodErrorMessage(undefined)
              onUpload(files[0])
            }}
            onDropRejected={(files) => onReject(files)}
            multiple={false}
            accept={'application/pdf'}
            maxSize={11000000}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <Button
                  onClick={() => {}}
                  className={classes.button}
                  icon={<CloudUpload className={classes.buttonIcon} />}
                >
                  charger
                  <input {...getInputProps()} />
                </Button>
              </div>
            )}
          </ReactDropzone>
          )}
          {(!!onView || conventionLoading) && (
            <Button
              className={classes.button}
              isLoading={conventionLoading}
              onClick={onView}
              icon={<VisibilityOutlined className={classes.buttonIcon} />}
            >
              {t('app_organizations_documents_CA_Convention_view')}
            </Button>
          )}
          {(onPrefill || prefilled) && (
            <Button
              className={classes.button}
              onClick={onPrefill}
              success={prefilled}
              icon={
                <EditDocument
                  color='white'
                  strokeWidth={1.5}
                  className={classes.buttonIcon}
                />
              }
            >
              {prefilled
                ? t('app_organizations_documents_CA_Convention_prefilled')
                : t('app_organizations_documents_CA_Convention_prefill')}
            </Button>
          )}
          {isDocusign && (onSign || signed) && (
            <Button
              className={classes.button}
              onClick={onSign}
              success={signed}
              icon={
                <EditDocument
                  color='white'
                  strokeWidth={1.5}
                  className={classes.buttonIcon}
                />
              }
            >
              {signed
                ? t('app_organizations_documents_CA_Convention_signed')
                : t('app_organizations_documents_CA_Convention_sign')}
            </Button>
          )}
          {isDocusign && onReset && (
            <React.Fragment>
              <Button
                className={classes.button}
                onClick={handleOpenReset}
                advertissement
                noIcon
              >
                {t('app_organizations_documents_CA_Convention_reset')}
              </Button>

              {openReset && (
                <PopUpConfirmation
                  title={t(
                    'app_organizations_documents_CA_Convention_reset_popup_title'
                  )}
                  onClose={handleCloseReset}
                  onConfirm={handleReset}
                  open={openReset}
                >
                  <p>
                    {t(
                      'app_organizations_documents_CA_Convention_reset_popup_text'
                    )}
                  </p>
                </PopUpConfirmation>
              )}
            </React.Fragment>
          )}
        </Box>
      </Box>
    </Box>
  )
}
