import { keycloakClient } from "api/keyclaok";
import {
  Convention,
  ConventionActions,
  ConventionStatusValues,
  ConventionTypeValues,
  Organization,
  organizationClient,
} from "api/organization";
import { conventionTemplateImageUrl } from "api/organization/query/conventionTemplateImageUrl";
import { ConventionViewer } from "components";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import withConnect from "./withConnect";

interface OrganizationDocumentsProps {
  organization: Organization | null;
  convention: Convention | null;
  conventionActions: ConventionActions;
  prepareSignConvention: (organizationId: string) => void;
  resetConvention: (organizationId: string) => Promise<void>;
  fetchConvention: (organizationId: string) => void;
  uploadSimplifiedConvention: (organizationId: string, file: string, name: string) => Promise<void>;
  docusignEvent?: string;
}

const OrganizationDocuments = (props: OrganizationDocumentsProps) => {
  const {
    organization,
    conventionActions,
    convention,
    prepareSignConvention,
    resetConvention,
    fetchConvention,
    uploadSimplifiedConvention,
    docusignEvent,
  } = props;
  const { organizationId } = useParams();
  const [localPdfUrl, setLocalPdfUrl] = useState<string>("");
  const [localPdfImage, setLocalPdfImage] = useState<any>();

  useEffect(() => {
    if (convention == null) {
      fetchConvention(organizationId);
    }
  }, [organization, organizationId, convention]);

  useEffect(() => {
    fetchConventionFile()
  }, [convention]);

  useEffect(() => {
    conventionTemplateImageUrl(organizationId).then(async(res: Response) => {
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setLocalPdfImage(imageObjectURL)
    }).catch(error=>{ console.log("error", error)})
  }, [localPdfUrl])

  const fetchConventionFile = useCallback(() => {
    organizationClient.query
        .fetchConventionFile(organizationId)
        .then((document) => {
          if (document != undefined) {
            setLocalPdfUrl(URL.createObjectURL(document));
          }
        });
  }, [organizationClient.query.fetchConventionFile, organizationId, setLocalPdfUrl])

  const { t } = useTranslation();

  const onSignCLick = useCallback(async () => {
    await prepareSignConvention(organizationId);
  }, [prepareSignConvention, organizationId]);

  const isSigned = () => {
    if (!convention) return false;

    if (convention.status.value === ConventionStatusValues.FINALIZED.value)
      return true;

    if (
      convention.status.value === ConventionStatusValues.SIGNED_BY_ORG.value &&
      (keycloakClient.instance.hasRealmRole("operator_admin") ||
        keycloakClient.instance.hasRealmRole("operator_admin_onboarding")) &&
      !keycloakClient.instance.hasRealmRole("admin")
    )
      return true;

    return false;
  };

  const isPrefilled = () => {
    return (
      convention != null &&
      convention.status.value === ConventionStatusValues.PREFILLED.value &&
      keycloakClient.instance.hasRealmRole("admin")
    );
  };

  const createOnView = () => {
    return localPdfUrl !== ""
      ? () => window.open(localPdfUrl, "_blank")
      : undefined;
  };

  const handleReset = useCallback(async () => {
    await resetConvention(organizationId);
    fetchConvention(organizationId);
  }, [resetConvention, organizationId]);

  const isColisactiv = keycloakClient.instance.hasRealmRole("admin");

  const onUploadSimplifiedConvention = useCallback((file: File) => {
    const blobInvoice = new Blob([file], {
      type: file.type,
    });
    const reader = new FileReader();
    reader.readAsDataURL(blobInvoice);
    reader.onload = async () => {
      await uploadSimplifiedConvention(
        organizationId,
        reader.result as string,
        file.name
      ).then(() => {
        fetchConvention(organizationId)
      });
    };
  }, [organizationId, uploadSimplifiedConvention])

  const isDocusign = useMemo(() => {
    const typeIsDocusign = (organization?.conventionSelected != null ? ConventionTypeValues.isDocusign(organization.conventionSelected.value) : null)
    return (typeIsDocusign == true || typeIsDocusign == null) && !(convention?.id?.includes("simplified") == true)
  }, [organization?.conventionSelected, convention])

  return (
      <ConventionViewer
        imageSrc={localPdfImage}
        title={`${t("app_organizations_documents_CA_Convention", {
          operatorName: organization?.details.name,
        })}.pdf`}
        signed={isSigned()}
        prefilled={isPrefilled()}
        onPrefill={conventionActions.canPrefill ? onSignCLick : undefined}
        onSign={conventionActions.canSign ? onSignCLick : undefined}
        onView={createOnView()}
        onReset={isColisactiv ? handleReset : undefined}
        onUpload={onUploadSimplifiedConvention}
        conventionLoading={!!convention && localPdfUrl === ""}
        isDocusign={isDocusign}
      />
  );
};

export default withConnect(OrganizationDocuments);
