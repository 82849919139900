import { OrganizationDetails, OrganizationType, PackageType } from "../index";
import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "../../configuration";
import { http } from "../../http";

export interface CreateOrganizationCommand
  extends city.colisactiv.organization.model.features
    .CreateOrganizationCommand {}

export interface OrganizationEventDetails
  extends city.colisactiv.organization.model.features
    .OrganizationEventDetails {}

export interface OrganizationCreatedEvent
  extends city.colisactiv.organization.model.features.OrganizationCreatedEvent {
  type: string;
  payload: OrganizationEventDetails;
}

export const organizationCreateCommand = (
  type: OrganizationType,
  details: OrganizationDetails,
  packageType?: PackageType,
  packagePerPallet?: number,
  useDocusign?: boolean
): CreateOrganizationCommand => {
  return {
    type: "CREATE_ORGANIZATION",
    payload: {
      details: details,
      type: type,
      packageType: packageType,
      packagePerPallet: packagePerPallet,
      useDocusignConvention: useDocusign
    },
  };
};

export const createOrganizationCommand = (
  type: OrganizationType,
  details: OrganizationDetails,
  packageType?: PackageType,
  packagePerPallet?: number,
  useDocusign?:boolean
): Promise<OrganizationCreatedEvent> => {
  return http.post(
    buildOrganizationApiUrl(`/organizations`),
    JSON.stringify(organizationCreateCommand(type, details, packageType, packagePerPallet, useDocusign)),
    "L'organisation a bien été ajoutée"
  );
};
