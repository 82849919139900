import React, { useCallback, useEffect, useMemo, useState } from "react";
import withConnect from "./withConnect";
import PageWithTitle from "app/components/PageWithTitle/PageWithTitle";
import {
  Button,
  ComponentLoading,
  DashboardFilters,
  DashboardFiltersModel,
  DashboardGrid,
  DownloadButton,
  Option,
} from "components";
import { makeStyles } from "@material-ui/core";
import { User } from "api/organization/users";
import { useAuth } from "auth/useAuth";
import { Heatmap } from "app/components/Heatmap";
import { dashboardClient, HeatmapData, PoiDashboardStats } from "api/dashboard";
import { Organization, OrganizationRef } from "../../../api/organization";
import {
  displayNumber,
  formatDate,
  nullToUndefined,
  wholeMonthOf,
} from "utils";
import { Territory, geoZoneClient } from "api/geoZone";
import { PoiDashboardFilter } from "api/dashboard/query/fetchPoiDashboardStats";
import { routesAccessRights } from "auth/RoutesAccessRightsMap";
import { bonusGroupStatsClient } from "api/bonusGroupStats";
import { DashboardExportForm } from "app/components/DashboardExportForm";
import { DashboardAdminExportForm } from "../../components/DashboardAdminExportForm";
import { deliveryExportClient } from "api/deliveryExport";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: "fit-content",
    maxWidth: "100%",
    margin: "0 auto",
  },
  indicatorContainer: {
    marginTop: 50,
    display: "flex",
    justifyContent: "center",
  },
  tableTitle: {
    marginLeft: 25,
    marginBottom: 10,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 19,
    color: "#007DCE",
  },
  poiContainer: {
    marginTop: 50,
  },
  mapStyle: {
    width: "500px",
    height: "282px",
    marginLeft: "30px",
  },
  button: {
    marginRight: "10px",
    marginTop: "3px",
  },
}));

enum LoadingState { NotLoaded, Loading, Loaded }

interface DashboardPageProps {
  organizationRefs: Map<string, OrganizationRef>;
  carrierRefs: Map<string, OrganizationRef>;
  operatorRefs: Map<string, OrganizationRef>;
  currentUser: User | null;
  organization: Organization | null;
  fetchOrganization: (organizationId: string) => void;
}

const DashboardPage = (props: DashboardPageProps) => {
  const {
    organizationRefs,
    currentUser,
    carrierRefs,
    operatorRefs,
    organization,
    fetchOrganization,
  } = props;
  const [authService, keycloak] = useAuth();
  const classes = useStyles();
  const authResult = new URLSearchParams(window.location.search);
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [dashboardStats, setDashboardStats] = useState<PoiDashboardStats | undefined>(undefined);
  const [heatmapData, setHeatmapData] = useState<HeatmapData | undefined>(undefined);
  const start = formatDate(authResult.get("startDate"));
  const end = formatDate(authResult.get("endDate"));
  const territoryName = nullToUndefined(authResult.get("territoryName"));
  const territoryId = nullToUndefined(authResult.get("territoryId"));
  const territoryCode = nullToUndefined(authResult.get("territoryCode"));
  const [filterValues, setFiltersValue] = useState<DashboardFiltersModel>({
    startPeriod: start
      ? {
        startDate: wholeMonthOf(start).startDate,
        endDate: wholeMonthOf(start).endDate,
      }
      : undefined,
    endPeriod: end
      ? {
        startDate: wholeMonthOf(end).startDate,
        endDate: wholeMonthOf(end).endDate,
      }
      : undefined,
    operatorId: nullToUndefined(authResult.get("operatorId")),
    carrierId: nullToUndefined(authResult.get("carrierId")),
    territory:
      territoryName && territoryId && territoryCode
        ? {
          code: territoryCode,
          id: territoryId,
          name: territoryName,
          zones: [],
        }
        : undefined,
  });

  const isColisActivAdmin = useMemo(
    () => authService.isColisActivAdmin(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );
  const isColisActivOwner = useMemo(
    () => authService.isColisActivOwner(currentUser, keycloak),
    [authService, currentUser, keycloak]
  );
  const isCarrier = useMemo(
    () => !isColisActivAdmin && authService.isCarrier(currentUser, keycloak),
    [authService, currentUser, isColisActivAdmin, keycloak]
  );
  const isOperator = useMemo(
    () => !isColisActivAdmin && authService.isOperator(currentUser, keycloak),
    [authService, currentUser, isColisActivAdmin, keycloak]
  );
  const isFunder = useMemo(
    () => !isColisActivAdmin && authService.isFunder(currentUser, keycloak),
    [authService, currentUser, isColisActivAdmin, keycloak]
  );
  const isVisitor = useMemo(
    () => !isColisActivAdmin && authService.isVisitor(currentUser, keycloak),
    [authService, currentUser, isColisActivAdmin, keycloak]
  );

  const getOperators = useCallback((): Option[] => {
    return Array.from(operatorRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId,
      };
    });
  }, [operatorRefs]);

  const getCarriers = useCallback((): Option[] => {
    return Array.from(carrierRefs.values()).map((el) => {
      return {
        label: el.displayName,
        value: el.organizationId,
      };
    });
  }, [carrierRefs]);

  const canSeeOperatorFilter = useMemo((): boolean => {
    return (
      authService.isAuthorized(
        routesAccessRights["/dashboard?operatorFilter"],
        keycloak
      ) &&
      (!isVisitor ||
        organization?.visitorAuthorization?.watchedCarrierId !== null)
    );
  }, [authService, keycloak, isVisitor, organization]);

  const canSeeCarrierFilter = useMemo((): boolean => {
    return (
      authService.isAuthorized(
        routesAccessRights["/dashboard?carrierFilter"],
        keycloak
      ) &&
      (!isVisitor ||
        organization?.visitorAuthorization?.watchedOperatorId !== null)
    );
  }, [authService, keycloak, isVisitor, organization]);

  const onBonusFiltersChange = (values: DashboardFiltersModel) => {
    setFiltersValue({
      startPeriod: values.startPeriod,
      endPeriod: values.endPeriod,
      operatorId: values.operatorId,
      carrierId: values.carrierId,
      territory: values.territory,
    });
  };

  const fetchIndicatorsAndHeatMap = useCallback(async () => {
    const filters: PoiDashboardFilter = {
      startPeriod: filterValues.startPeriod,
      endPeriod: filterValues.endPeriod,
      operatorId: filterValues.operatorId,
      carrierId: filterValues.carrierId,
      territoryCode: filterValues.territory?.code,
    };
    dashboardClient.query.fetchPoiDashboardStats({ ...filters }).then((stats) => {
      if (stats) setDashboardStats(stats);
    });
    setHeatmapData(undefined);
    dashboardClient.query.getHeatmapData({ ...filters }).then(async (heatmapData) => {
      if (heatmapData) {
        const data = await heatmapData.text();
        setHeatmapData(JSON.parse(data) as HeatmapData);
      }
    });
  }, [filterValues]);

  useEffect(() => {
    if (organization === null && currentUser !== null) {
      fetchOrganization(currentUser!!.details.organizationId!!);
    }
  }, [organization, currentUser, fetchOrganization]);

  useEffect(() => {
    if (loadingState === LoadingState.Loading) {
      fetchIndicatorsAndHeatMap().then(() => {
        setLoadingState(LoadingState.Loaded)
      })
    }
  }, [loadingState, filterValues]);

  useEffect(() => {
    if (organization && loadingState === LoadingState.NotLoaded) {
      if (isOperator) {
        setFiltersValue({
          ...filterValues,
          operatorId: currentUser?.details.organizationId || undefined,
        });
      } else if (isCarrier) {
        setFiltersValue({
          ...filterValues,
          carrierId: currentUser?.details.organizationId || undefined,
        });
      } else if (isVisitor) {
        setFiltersValue({
          ...filterValues,
          territory:
            organization?.territories && organization.territories.length > 0
              ? organization?.territories[0]
              : undefined,
          operatorId:
            organization?.visitorAuthorization?.watchedOperatorId || undefined,
          carrierId:
            organization?.visitorAuthorization?.watchedCarrierId || undefined,
        });
      }
      setLoadingState(LoadingState.Loading)
    }
  }, [currentUser, organization, loadingState, isOperator, isCarrier, isVisitor, fetchIndicatorsAndHeatMap, filterValues]);

  const handleFiltersValidation = useCallback(() => {
    fetchIndicatorsAndHeatMap()
  }, [fetchIndicatorsAndHeatMap])

  const handleBonusExport = useCallback(async () => {
    const objectUrl = await bonusGroupStatsClient.query.exportBonusGroupStats(
      filterValues.startPeriod,
      filterValues.endPeriod
    );
    if (objectUrl == null) {
      return;
    }
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = "statistiques.csv";
    a.click();
  }, [filterValues]);

  const canExportDelivery = useMemo(
    (): boolean =>
      isColisActivAdmin ||
      ((isFunder || isVisitor) &&
        organization?.exportAuthorization?.deliveryPeriod != null),
    [isColisActivAdmin, isFunder, isVisitor, organization]
  );
  const canExportTrace = useMemo(
    (): boolean =>
      isColisActivAdmin ||
      ((isFunder || isVisitor) &&
        organization?.exportAuthorization?.tracePeriod != null),
    [isColisActivAdmin, isFunder, isVisitor, organization]
  );
  const showExportForm = useMemo(
    (): boolean => canExportDelivery || canExportTrace,
    [canExportDelivery, canExportTrace]
  );
  const showHeatmap = useMemo(
    (): boolean =>
      !isVisitor ||
      authService.hasRoles(currentUser!, ["read_heatmap"], keycloak),
    [authService, currentUser, isVisitor, keycloak]
  );
  const allowedTerritories = useMemo((): string[] | undefined => {
    if (isVisitor && organization?.territories) {
      return organization.territories.length > 0
        ? organization?.territories?.map(
          (territory: Territory) => territory.code
        )
        : undefined;
    }
    return undefined;
  }, [isVisitor, organization]);

  const handleDeliveryExportAll = useCallback(async () => {
    await deliveryExportClient.query.exportAllDelivery();
  }, []);

  const handleTraceExportAll = useCallback(async () => {
    await deliveryExportClient.query.exportAllTrace();
  }, []);

  const headBar = useMemo(
    () => ({
      title: "Tableau de bord",
    }),
    []
  );

  if (dashboardStats == null || organizationRefs.size === 0) {
    return <ComponentLoading />;
  }

  return (
    <div>
      <PageWithTitle
        headBar={headBar}
        header={
          <>
            <DashboardFilters
              operators={getOperators()}
              carriers={getCarriers()}
              allowedTerritories={allowedTerritories}
              onFilterChange={onBonusFiltersChange}
              getTerritories={geoZoneClient.query.getTerritories}
              filterValues={filterValues}
              canSeeOperatorFilter={canSeeOperatorFilter}
              canSeeCarrierFilter={canSeeCarrierFilter}
              canSeeTerritoryFilter={!isFunder}
            />
            <Button className={classes.button} onClick={handleFiltersValidation}>
              Valider
            </Button>
            {isColisActivAdmin && (
              <DownloadButton className={classes.button} onClick={handleBonusExport} label="Exporter les trouples"/>
            )}
          </>
        }
        columnSwitchWidth={800}
        switchedHeaderHeight={150}
      >
        <div className={classes.container}>
          <div className={classes.indicatorContainer}>
            <DashboardGrid
              activePackCount={displayNumber(dashboardStats.activePackCount)}
              unwrappedPalletsPackCount={displayNumber(dashboardStats.unwrappedPalletsPackCount)}
              manualPackCount={displayNumber(
                dashboardStats.manuallyActivePackCount
              )}
              autoPackCount={displayNumber(dashboardStats.autoActivePackCount)}
              hubsCount={dashboardStats.hubCount}
              bonusTotal={displayNumber(dashboardStats.bonusTotal, 2)}
              bonusToDeduce={displayNumber(dashboardStats.bonusToDeduce, 2)}
              bonusToReport={displayNumber(dashboardStats.bonusToReport, 2)}
              bonusDeduced={displayNumber(dashboardStats.bonusDeduced, 2)}
              bonusPaid={displayNumber(dashboardStats.bonusPaid, 2)}
              bonusCreated={displayNumber(dashboardStats.bonusCreated, 2)}
              bonusInvalid={displayNumber(dashboardStats.bonusInvalid, 2)}
              deliveryTime={dashboardStats.durationTotal}
              manualDeliveryTime={dashboardStats.manuallyDeclaredDuration}
              autoDeliveryTime={dashboardStats.autoDeclaredDuration}
              activeDeliveries={displayNumber(
                dashboardStats.activeDeliveryPointCount
              )}
              bonusPerPackage={displayNumber(dashboardStats.bonusPerPackage, 2)}
              tourAmount={displayNumber(dashboardStats.tourAmount)}
              averageDistancePerDeliveryWalkBike={displayNumber(
                dashboardStats.averageDistancePerDeliveryWalkBike
              )}
              distanceTotalWalkBikeAverage={displayNumber(
                dashboardStats.distanceTotalWalkBikeAverage
              )}
              averageApproachDurationBike={dashboardStats.averageApproachDurationBike}
              averageApproachDistanceWalkBike={displayNumber(
                dashboardStats.averageApproachDistanceWalkBike
              )}
              averageApproachDistancePerDeliveryWalkBike={displayNumber(
                dashboardStats.averageApproachDistancePerDeliveryWalkBike
              )}
              thermalKmAvoided={displayNumber(dashboardStats.thermalKmAvoided)}
              totalThermalKmAvoided={displayNumber(dashboardStats.totalThermalKmAvoided)}
              co2TonsAvoided={displayNumber(dashboardStats.co2TonsAvoided)}
              noxTonsAvoided={displayNumber(dashboardStats.noxTonsAvoided, 2)}
              ppmTonsAvoided={(dashboardStats.ppmTonsAvoided * 1000).toFixed(0)}
              fuelAvoided={displayNumber(dashboardStats.fuelAvoided)}
              isAdmin={isColisActivAdmin}
              visitorRoles={
                isVisitor ? keycloak?.realmAccess?.roles || [] : null
              }
            />
            {showHeatmap && (
              <>
                {heatmapData ? (
                  <Heatmap
                    deliveryPoints={heatmapData ? heatmapData.deliveries : []}
                    pickupPoints={heatmapData ? heatmapData.pickups : []}
                    southWestBound={
                      heatmapData ? heatmapData.southWestBound : []
                    }
                    northEastBound={
                      heatmapData ? heatmapData.northEastBound : []
                    }
                  />
                ) : (
                  <ComponentLoading className={classes.mapStyle} />
                )}
              </>
            )}
          </div>
          {showExportForm && (
            <DashboardExportForm
              organization={organization}
              isFunder={isFunder}
              isAdmin={isColisActivAdmin}
              isVisitor={isVisitor}
              canExportDelivery={canExportDelivery}
              canExportTrace={canExportTrace}
              allowedTerritories={allowedTerritories}
            />
          )}
          {isColisActivOwner && (
            <DashboardAdminExportForm
              handleDeliveryExportAll={handleDeliveryExportAll}
              handleTraceExportAll={handleTraceExportAll}
            />
          )}
        </div>
      </PageWithTitle>
    </div>
  );
};

export default withConnect(DashboardPage);
