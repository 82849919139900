import { createActionCreator } from "deox";
import { Convention, Organization } from "../../api/organization";
import { PaymentDetails } from "../../api/organization/command/updatePaymentInfo";
import { OrganizationDetailsFormState } from "../../app/components/OrganizationDetailsForm/model";

const fetchOrganization = createActionCreator(
  "ORGANIZATION_FETCH",
  (resolve) => (organizationId: string) =>
    resolve({ organizationId: organizationId })
);

const fetchedOrganization = createActionCreator(
  "ORGANIZATION_FETCHED",
  (resolve) => (organization: Organization) =>
    resolve({ organization: organization })
);

const setOrganizationToNull = createActionCreator(
  "ORGANIZATION_SET_TO_NULL",
  (resolve) => () =>
    resolve()
);

const fetchConvention = createActionCreator(
  "CONVENTION_FETCH",
  (resolve) => (organizationId: string) =>
    resolve({ organizationId: organizationId })
);

const fetchedConvention = createActionCreator(
  "CONVENTION_FETCHED",
  (resolve) => (convention: Convention|null) => resolve({ convention: convention })
);

const createOrganization = createActionCreator(
  "ORGANIZATION_UPDATE",
  (resolve) => (organizationForm: OrganizationDetailsFormState) =>
    resolve({ organizationForm })
);

const createdOrganization = createActionCreator(
  "ORGANIZATION_UPDATED",
  (resolve) => (organization: Organization) => resolve({ organization })
);

const updateOrganization = createActionCreator(
  "ORGANIZATION_UPDATE",
  (resolve) => (
    organizationId: string,
    organizationForm: OrganizationDetailsFormState
  ) => resolve({ organizationId, organizationForm })
);

const updatedOrganization = createActionCreator(
  "ORGANIZATION_UPDATED",
  (resolve) => (organization: Organization) => resolve({ organization })
);

const updateOrganizationPaymentInfo = createActionCreator(
  "UPLOAD_PAYMENT_INFORMATIONS",
  (resolve) => (organizationId: string, payment: PaymentDetails) =>
    resolve({ organizationId, payment })
);

const deleteOrganization = createActionCreator(
  "ORGANIZATION_DELETE",
  (resolve) => (organizationId: string) => resolve({ organizationId })
);

const deletedOrganization = createActionCreator(
  "ORGANIZATION_DELETED",
  (resolve) => (organizationId: string) => resolve({ organizationId })
);

export const actions = {
  fetchOrganization: fetchOrganization,
  fetchedOrganization: fetchedOrganization,
  fetchConvention: fetchConvention,
  fetchedConvention: fetchedConvention,
  createOrganization: createOrganization,
  createdOrganization: createdOrganization,
  updateOrganization: updateOrganization,
  updatedOrganization: updatedOrganization,
  deleteOrganization: deleteOrganization,
  deletedOrganization: deletedOrganization,
  updatePaymentInfo: updateOrganizationPaymentInfo,
  setOrganizationToNull: setOrganizationToNull,
};
