import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "../../http";
import { Convention } from "../index";

export const fetchConvention = (
  organizationId: string
): Promise<Convention|undefined> => {
  return http.get(
    buildOrganizationApiUrl(`/organizations/${organizationId}/convention`),
    "La récupération des status de convention n'a pas pu être effectuée"
  );
};
