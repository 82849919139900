import * as R from "ramda";
import {
  OrganizationDetails,
  OrganizationType,
  PackageType,
} from "../../../api/organization";
import { NO_ERROR } from "../SiretAutocomplete";
import i18n from "i18n";
import {
  OrganizationDetailsFormState,
  OrganizationDetailsFormValidity,
} from "./model";

export const getValidation = (
  details: OrganizationDetails,
  organizationType: OrganizationType,
  packageType?: PackageType,
  packagePerPallet?: number
): OrganizationDetailsFormValidity => {
  return {
    city: isCityFilled(details.address.city),
    name: isNameFilled(details.name),
    siret: isSiretValid(details.siret),
    street: isStreetFilled(details.address.street),
    type: isTypeFilled(organizationType.type),
    zipCode: isZipCodeFilled(details.address.zipCode),
    packageType:
      organizationType.type === "Carrier"
        ? isPackageTypeFilled(packageType?.value)
        : NO_ERROR,
    packagePerPallet:
      organizationType.type === "Carrier"
        ? isPackagePerPalletFilled(packagePerPallet)
        : NO_ERROR,
  };
};

function isCityFilled(city: string) {
  if (city !== "") return NO_ERROR;
  else return i18n.t("app_organization_form_please_insert_a_city");
}

function isNameFilled(name: string) {
  if (name !== "") return NO_ERROR;
  else return i18n.t("app_organization_form_please_insert_a_name");
}

function isSiretValid(siret: string) {
  if (siret.length === 14) return NO_ERROR;
  else return i18n.t("app_organization_form_please_insert_a_correct_siret");
}

function isStreetFilled(street: string) {
  if (street !== "") return NO_ERROR;
  else return i18n.t("app_organization_form_please_insert_an_address");
}

function isZipCodeFilled(zipCode: string) {
  if (zipCode !== "") return NO_ERROR;
  else return i18n.t("app_organization_form_please_enter_a_postal_code");
}

function isTypeFilled(type: string) {
  if (type !== "") return NO_ERROR;
  else return i18n.t("app_organization_form_please_select_a_type");
}

function isPackageTypeFilled(packageType: string) {
  if (packageType !== "") return NO_ERROR;
  else return i18n.t("app_organization_form_please_select_a_package_type");
}

function isPackagePerPalletFilled(packagePerPallet?: number) {
  if (!packagePerPallet || Number.isInteger(packagePerPallet)) return NO_ERROR;
  else return i18n.t("app_organization_form_please_select_a_package_type");
}

export const isFormValid = (
  newValidity: OrganizationDetailsFormValidity
): boolean => {
  return R.values(newValidity)
    .map((value) => value == NO_ERROR)
    .reduce((previousValue, currentValue) => previousValue && currentValue);
};

export const defaultOrganizationDetailsFormValidity: OrganizationDetailsFormValidity =
  {
    city: NO_ERROR,
    name: NO_ERROR,
    siret: NO_ERROR,
    street: NO_ERROR,
    type: NO_ERROR,
    packageType: NO_ERROR,
    packagePerPallet: NO_ERROR,
    zipCode: NO_ERROR,
    useDocusign: NO_ERROR
  };

export const defaultOrganizationDetailsFormState: OrganizationDetailsFormState =
  {
    friendlyId: "",
    details: {
      name: "",
      siret: "",
      address: {
        street: "",
        zipCode: "",
        city: "",
      },
    },
    type: { type: "" },
    packageType: undefined,
    packagePerPallet: undefined,
    validity: defaultOrganizationDetailsFormValidity,
    logo: undefined,
    useDocusign: undefined
  };

export const OrganizationDetailsValidation = {
  validation: getValidation,
  isFormValid: isFormValid,
  defaultOrganizationDetailsFormValidity:
    defaultOrganizationDetailsFormValidity,
  defaultOrganizationDetailsFormState: defaultOrganizationDetailsFormState,
};
