import { city } from "ca-plateform-organization-domain";
import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "../../http";

export interface SimplifiedConventionUploadedEvent
  extends city.colisactiv.organization.model.features
    .SimplifiedConventionUploadedEvent {}

export interface UploadSimplifiedConventionCommand
extends city.colisactiv.organization.model.features
  .UploadSimplifiedConventionCommand {}


export const createUploadSimplifiedConventionCommand = (
  id: string,
  file: string,
  name: string,
): UploadSimplifiedConventionCommand => {
  return {
    type: "UPLOAD_SIMPLIFIED_CONVENTION",
    payload: {
      id: id,
      file: file,
      name: name,
    },
  };
};

export const uploadSimplifiedConvention = (
  organizationId: string, file: string, name: string
): Promise<SimplifiedConventionUploadedEvent> => {
  return http.post(
    buildOrganizationApiUrl(
      `/organizations/${organizationId}/convention/simplified/upload`
    ),
    JSON.stringify(createUploadSimplifiedConventionCommand(organizationId, file, name)),
    "La convention a bien été importée"
  );
};
