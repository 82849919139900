import { buildOrganizationApiUrl } from "api/configuration";
import { http } from "api/http";
import { keycloakClient } from "api/keyclaok";

export const conventionTemplateImageUrl = (
  organizationId: string
): Promise<Response> => {
  return fetch(buildOrganizationApiUrl(
    `/organizations/${organizationId}/convention/template/image`
  ), {
    method: "get",
    headers: {
      Authorization: `Bearer ${keycloakClient.instance.token}`,
    },
  })
};
